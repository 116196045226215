<script>
export default {
  name: 'Logout',
  render () {
    return ''
  },
  async mounted () {
    await this.$store.dispatch('auth/logout')
    this.$router.push({ name: 'login' })
  }
}
</script>
